export const baseUrl = process.env.REACT_APP_GRAPH_RAG_URL;
export const askWebSocketUrl = "wss://graph-rag.staging.agolo.com/api/answers/v1/ws/ask";
export const SHOW_SETTINGS = false;

export const DEFAULT_ERROR_MSG =
  "Something went wrong, Please try again later.";

export enum NavItems {
  QuestionAnswer = "/question-answer",
  Chat = "/chat",
  Logs = "/logs",
}

export const GENERIC_SIZE_OPTIONS = [10, 25, 50, 100];

export const DEFAULT_LOGS_PAGINATION = {
  pageSize: 10,
  pageIndex: 0,
};

export const sidebarWidth = "28vw";
